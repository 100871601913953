import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import HeroSection from '../components/HeroSection/HeroSection';
import './about.scss';
import { AboutSVG, AboutBlogSVG } from '../static/svgs';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import { ABOUT_PAGE_SEO_DESC, PROFILE_IMAGE_URL } from '../utils/constants';
import { getRandomNumber } from '../utils/common';

function About(props) {
  const min = 1;
  const max = 4;
  const [profileImageId, setProfileImageId] = useState(() => getRandomNumber({ min, max }));

  const updateProfileImageId = () => {
    let newNum;
    do {
      newNum = getRandomNumber({ min, max });
    } while (newNum === profileImageId);
    setProfileImageId(newNum);
  };

  const renderHeroSection = () => <HeroSection title={'About'} svg={<AboutSVG />} />;

  return (
    <Layout
      location={props.location}
      customClass={'aboutWrapper'}
      renderOutsideContainer={renderHeroSection}
    >
      <SEO title={'About'} description={ABOUT_PAGE_SEO_DESC} url={'/about'} />

      <div className={'content-container'}>
        <div className="aboutPageContainer">
          <div className="aboutTitle">
            <div className="aboutTitleText">
              <div className="aboutTitleTextHeading">
                <h2>About Me</h2>
              </div>

              <div className="aboutTitleTextContent">
                <p>
                  Hi! I'm Raj. I'm a{' '}
                  <a
                    href="https://blog.pragmaticengineer.com/the-product-minded-engineer/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    product engineer.
                  </a>{' '}
                  I enjoy wearing multiple hats and building products that solve real world
                  problems. I'm currently working at invideo, where I spend my time building the
                  best video editor to make it easy for anyone wanting to create videos.
                </p>

                <p>
                  Elixir is my favorite language, and the one I spend most of my time in. I also
                  work with React, Typescript, and Rust frequently. I co-host the{' '}
                  <a href="https://www.x.com/rustmumbai/" target="_blank" rel="noopener noreferrer">
                    RustMumbai
                  </a>{' '}
                  meetup.
                </p>

                <p>
                  I also like travelling{' '}
                  <a
                    href="https://www.instagram.com/_rajrajhans/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    and making videos.
                  </a>{' '}
                  Recently, I've gotten myself into building a self-hosted k8s cluster as a home
                  lab, I've named it "winterfell".
                </p>
              </div>
            </div>

            <div className="aboutTitlePhoto">
              <img
                src={PROFILE_IMAGE_URL(profileImageId)}
                alt={'Raj Rajhans'}
                onClick={updateProfileImageId}
              />
            </div>
          </div>

          <div className="aboutBlog">
            <div className="aboutBlogPhoto">
              <AboutBlogSVG />
            </div>

            <div className="aboutBlogText">
              <div className="aboutBlogTextHeading">
                <h2>About the Blog</h2>
              </div>

              <div className="aboutBlogTextContent">
                <p>Oppenheimer once said, “The best way to learn is to teach”.</p>
                <p>
                  Writing blog posts is one of my favorite ways to document my learnings. I like to
                  write because it helps me break down ideas into clearer thoughts, especially when
                  I need to revisit them months later. If you haven’t, go read{' '}
                  <a
                    href={'https://paulgraham.com/words.html'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                    title={"Paul Graham's essay"}
                  >
                    Paul Graham’s "Putting Ideas Into Words" essay.
                  </a>
                </p>

                <p>
                  Developers are creators, and documenting the creating process only improves the
                  developer’s skills. Writing about something solidifies the learning process.
                </p>

                <p>
                  <a href={'/2020/06/thinking-of-blog-as-digital-garden/'}>
                    I like to think of my blog as more of a digital garden, rather than a
                    traditional blog.
                  </a>{' '}
                  The aim is to document my ideas, projects, and learnings.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;

export const query = graphql`
  query AboutImageQuery {
    allFile(filter: { name: { eq: "raj-profile-pic-1" } }) {
      nodes {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
